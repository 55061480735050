import { useEffect, useState } from 'react';
import * as io from 'socket.io-client';

export enum StatusColor {
  progress = 'blue',
  complete = 'green',
  error = 'red',
}

const importsUrl: string = import.meta.env.VITE_API_URL || '__Xx__VITE_API_URL__xX__';
const disableTls: string = import.meta.env.VITE_DISABLE_TLS;

export type Progress = {
  progress: number;
  status: keyof typeof StatusColor;
};

export const useProgressManager = () => {
  const [progressList, setProgressList] = useState<{
    [key: string]: Progress;
  }>({});

  const reset = () => {
    setProgressList({});
  };

  useEffect(() => {
    if (!importsUrl) {
      return;
    }
    const ioClient = io.connect(`${disableTls ? 'ws' : 'https'}://${importsUrl}`);

    ioClient.on('connect', () => {
      console.log('connected');
    });

    ioClient.on('disconnect', () => {});

    ioClient.onAny((...args) => {
      setProgressList((prev) => {
        return { ...prev, [args[0]]: { progress: args[1], status: args[2] } };
      });
    });
    return () => {
      ioClient.disconnect();
    };
  }, [importsUrl]);
  return { progressList, reset };
};
